import {NsCrypto} from '@nanosystems/crypto';
import {
  ProductUranium,
  ProductSupremo,
  CartProductItemForCheckout,
  CheckoutPrice,
  PAYMENT_METHODS,
  ProductVersion,
} from '@utils/types/store';
import {
  Token,
  UserDataRequest,
  UserDataResponse,
  UserInfo,
  UserDashboardDiscount,
  UserInvite
} from '@utils/types/user';
import axios from 'axios';
import normalizeCheckout, {
  normalizeCheckoutProductRandomName,
  normalizeCheckoutToRawProducts,
  normalizeProfessionalProfile,
  normalizeCountryData,
  normalizeProvinceData,
  normalizeRegisterData,
  normalizeSupremoProduct,
  normalizeUraniumProduct,
  normalizeUserData,
  normalizeAddPurchase,
  normalizeError,
  normalizePaymentRecap,
  normalizePaymentResult,
  normalizeFormSignupError,
  normalizeCheckPayWithPaypal,
  normalizeConfirmPayment,
  normalizeLicenses,
  normalizeUpgradeRenewSupremoProduct,
  normalizeUpgradeRenewUraniumProduct,
  normalizeUpgradeRenewConsoleProduct,
  normalizePurchases,
  normalizeUserAccountData,
  normalizeProductToGetEval,
  normalizeUpgradedProductForCart,
  normalizeUpdateUserData,
  normalizeRecoveryPasswordError
} from './normalize';
import { Base64 } from 'js-base64';
import { first, last } from 'lodash';
import { ProvinceList, CountryList } from '@utils/types/form';
import {
  IPurchaseRequest,
  IPurchaseResponse,
  PAYMENT_DOWNLOAD_DOCUMENT,
} from '@utils/types/purchase';

import {
  ILicenseRequest,
  ILicenseResponse,
  IUpgradeProductForEvalPriceResponse,
  LICENSE_FILTERS,
  SORT_FIELD_LICENSE,
  SORT_ORDER_LICENSE,
} from '@utils/types/licenses';
import { formatDate } from '@utils/types/helpers';
import { checkActivationDateResponse } from '@hooks/useCheckActivationDate';
import { renderFieldName } from '@utils/purchasesHelperFunctions';

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.NODE_ENV === 'production' ? 60000 : 40000,
  withCredentials: true,
  headers: {
    'Accept-Language': 'it',
    'Content-Type': 'application/json',
  },
});

const getLoginValidation = (email: string) => {
  // Load road to ruin - how to retrive a specific letter as key
  const url = new URL(process.env.REACT_APP_API_URL || '');
  const domainComponents = url.hostname.split('.');
  const secretKey = domainComponents.length === 3 ? domainComponents[1].charAt(0) : '';
  const nsCrypto = new NsCrypto(secretKey, secretKey);
  const date = new Date();
  return nsCrypto.encryptAES256(email + '-' + date.toISOString());
};

export async function logUser(
  language: string,
  email: string,
  password: string,
  recaptcha: string
): Promise<Token> {
  try {
    const { data }: { data: any } = await http.post(
      '/login',
      {
        Username: email,
        Base64Password: Base64.encode(password),
        Recaptcha: recaptcha ?? '',
        Validation: getLoginValidation(Base64.encode(email))
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
    return data?.Token ?? undefined;
  } catch (err) {
    throw normalizeError(err);
  }
}

export async function autologUser(
  language: string,
  token: string,
): Promise<Token> {
  try {
    const { data }: { data: any } = await http.post(
      '/login/autologin',
      {
        token: token
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
    return data?.Token ?? undefined;
  } catch (err) {
    throw normalizeError(err);
  }
}

export async function registerUser(language: string, data: any): Promise<any> {
  try {
    await http.post(
      '/register/N',
      {
        ...normalizeRegisterData(data),
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
  } catch (err) {
    console.log('error in registerUser');

    throw normalizeFormSignupError(err);
  }
}

export async function registerUserQuick(language: string, data: any): Promise<any> {
  try {
    await http.post(
      '/register/N/quick',
      {
        ...normalizeRegisterData(data),
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
  } catch (err) {
    console.log('error in registerUserQuick');
    throw normalizeFormSignupError(err);
  }
}

export async function recoveryPassword(
  email: string,
  recaptcha: string
): Promise<void> {
  try {
    await http.post('/passwordrecovery', {
      Email: email,
      Recaptcha: recaptcha,
    });
  } catch (err) {
    console.log('error in recoveryPassword');
    throw normalizeRecoveryPasswordError(err);
  }
}

export async function getLoggedUserInfoFromApi(
  language: string
): Promise<UserInfo> {
  try {
    const { data }: { data: any } = await http.get('/login', {
      headers: {
        'Accept-Language': language,
      },
    });
    return normalizeUserData(data);
  } catch (err) {
    console.log('error in getLoggedUserInfoFromApi');
    throw normalizeError(err);
  }
}

export async function getCountryListFromApi(
  language: string
): Promise<CountryList[]> {
  try {
    const { data }: { data: any } = await http.get('/info/nazioni', {
      headers: {
        'Accept-Language': language,
      },
    });
    return normalizeCountryData(data);
  } catch (err) {
    console.log('error in getCountryListFromApi');

    throw normalizeError(err);
  }
}

export async function getProvinceListFromApi(
  language: string
): Promise<ProvinceList[]> {
  try {
    const { data }: { data: any } = await http.get('/info/province', {
      headers: {
        'Accept-Language': language,
      },
    });
    return normalizeProvinceData(data);
  } catch (err) {
    console.log('error in getProvinceListFromApi');

    throw normalizeError(err);
  }
}

export async function onSignOut(): Promise<void> {
  try {
    await http.delete('/login');
  } catch (err) {
    console.log('error in onSignOut');

    throw normalizeError(err);
  }
}

export async function getSupremoProduct(
  language: string
): Promise<ProductSupremo> {
  try {
    const { data }: { data: any } = await http.get('/store/N', {
      headers: {
        'Accept-Language': language,
      },
    });

    const { data: profiles }: { data: any } = await http.get(
      '/professionalProfile',
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return normalizeSupremoProduct({ ...first(data?.Items), profiles } ?? []);
  } catch (err) {
    console.log('error in getSupremoProduct');
    throw normalizeError(err);
  }
}

export async function getUraniumProduct(
  language: string
): Promise<ProductUranium> {
  try {
    const { data }: { data: any } = await http.get('/store/N', {
      headers: {
        'Accept-Language': language,
      },
    });
    return normalizeUraniumProduct(last(data?.Items) ?? []);
  } catch (err) {
    console.log('error in getUraniumProduct');
    throw normalizeError(err);
  }
}

export async function getUpgradeRenewProduct(
  language: string,
  id: string
): Promise<any> {
  try {
    const { data }: { data: any } = await http.get(`/upgrade/${id}`, {
      headers: {
        'Accept-Language': language,
      },
    });

    if (data?.software === 'SUPREMO') {
      return normalizeUpgradeRenewSupremoProduct(data ?? []);
    }
    if (data?.software === 'URANIUM') {
      return normalizeUpgradeRenewUraniumProduct(data ?? []);
    }
    if (data?.software === 'CONSOLE') {
      return normalizeUpgradeRenewConsoleProduct(data ?? []);
    }
  } catch (err) {
    console.log('error in getUpgradeRenewProduct');
    throw normalizeError(err);
  }
}

export async function getProfessionalProfile(language: string): Promise<any> {
  try {
    const { data }: { data: any } = await http.get('/professionalProfile', {
      headers: {
        'Accept-Language': language,
      },
    });
    return data
      ?.sort((a: any, b: any) => a.SortPriority > b.SortPriority)
      ?.map(normalizeProfessionalProfile);
  } catch (err) {
    console.log('error in professionalProfile');
    throw normalizeError(err);
  }
}

export async function getLicenseToRenew(): Promise<number> {
  try {
    const {
      data: { amount },
    }: { data: { amount: number } } = await http.get('/license/to_renew');

    return amount;
  } catch (err) {
    console.log('error in get license to renew');
    throw normalizeError(err);
  }
}

export async function checkout(
  language: string,
  items: CartProductItemForCheckout[],
  promotionalCode: string
): Promise<CheckoutPrice> {
  const products = items
    .map(normalizeCheckoutToRawProducts(false))
    .reduce(normalizeCheckoutProductRandomName, {});

  const discountCode = promotionalCode ? { code1: { promotionalCode } } : {};
  try {
    const { data }: { data: any } = await http.post(
      '/checkout',
      { Products: { ...products, ...discountCode } },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return normalizeCheckout(data);
  } catch (err) {
    console.log('error in /checkout');
    throw normalizeError(err);
  }
}

export async function getDiscountCheck(
  language: string,
  items: CartProductItemForCheckout[],
  promotionalCode: string
): Promise<boolean> {
  const products = items
    .map(normalizeCheckoutToRawProducts(false))
    .reduce(normalizeCheckoutProductRandomName, {});
  try {
    const { data }: { data: boolean } = await http.post(
      '/promoCode',
      {
        Products: { ...products, code1: { promotionalCode } },
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return data;
  } catch (err) {
    console.log('error in getDiscountCheck');
    throw normalizeError(err);
  }
}

export async function getB2BPecCheck(
  language: string,
  b2bPec: string
): Promise<boolean> {
  try {
    await http.post(
      '/B2BPEC',
      {
        MyString: b2bPec,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return true;
  } catch (err) {
    return false;
  }
}

export async function addPurchase(
  language: string,
  autoRenew = false,
  items: CartProductItemForCheckout[],
  paymentMethod: PAYMENT_METHODS,
  promotionalCode?: string,
  b2bPec?: string
) {
  const products = items
    .map(normalizeCheckoutToRawProducts(autoRenew))
    .reduce(normalizeCheckoutProductRandomName, {});
  try {
    const { data }: { data: any } = await http.post(
      '/addPurchase',
      {
        Products: { ...products, code1: { promotionalCode } },
        B2bpec: b2bPec,
        PurchaseMethod: paymentMethod,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return normalizeAddPurchase(data);
  } catch (err) {
    console.log('error in addPurchase');
    throw normalizeError(err);
  }
}

export async function getPaymentRecap(language: string, paymentId: string) {
  try {
    const { data }: { data: any } = await http.get(`/payRecap/${paymentId}`, {
      headers: {
        'Accept-Language': language,
      },
    });

    return normalizePaymentRecap(data);
  } catch (err) {
    throw normalizeError(err);
  }
}

export async function getPaymentResult(language: string, paymentId: string) {
  try {
    const { data }: { data: any } = await http.get(`/payResult/${paymentId}`, {
      headers: {
        'Accept-Language': language,
      },
    });

    return normalizePaymentResult(data);
  } catch (err) {
    throw normalizeError(err);
  }
}

// PURCHASES API

export const getPurchases = async (
  language: string,
  query: string,
  currentPage: number,
  itemsPerPage: number,
  sortDir: string,
  sortField: string
): Promise<IPurchaseResponse> => {
  try {
    const requestBody: IPurchaseRequest = {
      query: Base64.encode(query),
      currentPage,
      itemsPerPage,
      sortField: renderFieldName(sortField),
      sortDir,
    };

    const { data }: { data: IPurchaseResponse } = await http.post(
      '/purchase',
      {
        ...requestBody,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return {
      purchases: [...normalizePurchases(data?.purchases)],
      itemsPerPage: data?.itemsPerPage,
      maxPage: data?.maxPage,
      currentPage: data?.currentPage,
    };
  } catch (error) {
    console.error('API error in get purchases', error);
    throw normalizeError(error);
  }
};

export const getPurchaseDocumentDownload = async (
  language: string,
  paymentId: string,
  type: PAYMENT_DOWNLOAD_DOCUMENT
): Promise<string> => {
  try {
    const { data }: { data: any } = await http.post(
      '/download',
      {
        type,
        id: Number(paymentId),
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return data?.url;
  } catch (err) {
    console.log('error in get purchase document');
    throw normalizeError(err);
  }
};

// LICENSE API

export const getLicenses = async (
  language: string,
  filters: LICENSE_FILTERS,
  itemsPerPage: number,
  currentPage: number,
  query: string,
  sortField: SORT_FIELD_LICENSE,
  sortDir: SORT_ORDER_LICENSE,
  paymentId?: number
): Promise<ILicenseResponse> => {
  try {
    const requestBody: ILicenseRequest = {
      query: Base64.encode(query),
      filters,
      currentPage,
      itemsPerPage,
      sortDir,
      sortField,
      paymentId,
    };

    const { data }: { data: ILicenseResponse } = await http.post(
      '/license',
      {
        ...requestBody,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return {
      itemsPerPage: data?.itemsPerPage,
      licenses: [...normalizeLicenses(data?.licenses)],
      maxPage: data?.maxPage,
      currentPage: data?.currentPage,
    };
  } catch (err) {
    console.log('error in get License');
    throw normalizeError(err);
  }
};

export const getExpirationDate = async (
  language: string,
  id: string,
  isFromModal?: boolean
): Promise<checkActivationDateResponse> => {
  try {
    const { data } = await http.post(
      `/license/${id}/checkActivationData`,
      {},
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    const formattedDate = new Date(data?.expireDate);
    return {
      expirationDate: formatDate(formattedDate),
      isFromModal,
    };
  } catch (err) {
    console.log('error in get expiration date');
    console.log(err);
    throw normalizeError(err);
  }
};

export const getActivationCode = async (
  language: string,
  id: string,
  machineCode?: string
): Promise<string> => {
  try {
    const { data } = await http.post(
      `/license/${id}/activate`,
      {
        machineCode,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return data?.activationCode;
  } catch (err) {
    console.log('error in get activation code');
    throw normalizeError(err);
  }
};

export const editUserNote = async (
  language: string,
  id: string,
  note: string
): Promise<void> => {
  try {
    await http.post(
      `/license/${id}/notes`,
      {
        Notes: note,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
  } catch (err) {
    console.log('error in edit user note');
    throw normalizeError(err);
  }
};

export const activateAutoRenew = async (
  language: string,
  id: any
): Promise<string> => {
  try {
    const { data }: { data: any } = await http.get(`/license/${id}/autoRenew`, {
      headers: {
        'Accept-Language': language,
      },
    });

    return data?.url;
  } catch (err) {
    console.log('error in activate auto renew');
    throw normalizeError(err);
  }
};

export const deactivateAutoRenew = async (
  language: string,
  id: any
): Promise<boolean> => {
  try {
    const { data }: { data: boolean } = await http.get(
      `/license/${id}/disableAutoRenew`,
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return data;
  } catch (err) {
    console.log('error in deactivate auto renew');
    throw normalizeError(err);
  }
};

export const getLicensePDF = async (
  language: string,
  id: string
): Promise<string> => {
  try {
    const { data } = await http.post(
      `/license/${id}/proof`,
      {},
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return data;
  } catch (err) {
    console.log('error in get PDF license');
    throw normalizeError(err);
  }
};

export const getLicenseCSV = async (
  language: string,
  query: string,
  filters: LICENSE_FILTERS,
  paymentId?: number
): Promise<string> => {
  try {
    const { data } = await http.post(
      '/license/download_csv',
      {
        query: Base64.encode(query),
        filters,
        paymentId,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return data?.url;
  } catch (err) {
    console.log('error in get CSV license');
    throw normalizeError(err);
  }
};

export async function enablePayPalAgreement(action: string, token: string) {
  try {
    await http.post('/autoRenew/EnablePayPalAgreement', { action, token });
  } catch (err) {
    throw normalizeError(err);
  }
}

export async function getPayWithPaypal(language: string, paymentId: string) {
  let dataToNormalize = null;
  try {
    const { data }: { data: any } = await http.get(
      `/payWithPaypal/${paymentId}`,
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
    dataToNormalize = data;
  } catch (err) {
    throw normalizeError(err);
  }

  const normalizedData = normalizeCheckPayWithPaypal(dataToNormalize);
  if (normalizedData.amount.length > 0) {
    return normalizedData;
  } else {
    throw normalizeError({ response: { data: { Message: normalizedData.errorMessage } } });
  }
}

export async function confirmPayWithPaypal(
  language: string,
  paymentId: string
) {
  try {
    const { data }: { data: any } = await http.post(
      '/payWithPaypal/',
      { paymentId },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return normalizeConfirmPayment(data);
  } catch (err) {
    throw normalizeError(err);
  }
}

// PAYMENT METHOD

export enum LICENSE_AGREEMENT_STATUS {
  ACTIVE = 'ACTIVE',
  UNACTIVE = 'UNACTIVE',
  CANNOTCHECK = 'CANNOTCHECK',
}

export type LicenseAgreementResponse = {
  agreement_status: LICENSE_AGREEMENT_STATUS;
  url?: string | null;
};

export const checkPayPalAgreement = async (
  language: string
): Promise<LicenseAgreementResponse> => {
  try {
    const { data }: { data: any } = await http.get(
      '/autoRenew/checkPayPalAgreement',
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return {
      agreement_status: data?.agreement_status,
      url: data?.url,
    };
  } catch (err) {
    console.log('error in check paypal agreement');
    throw normalizeError(err);
  }
};

export const addPayPalAgreement = async (
  language: string
): Promise<LicenseAgreementResponse> => {
  try {
    const { data }: { data: any } = await http.get(
      '/autoRenew/addPayPalAgreement',
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return {
      agreement_status: data?.agreement_status,
      url: data?.url,
    };
  } catch (err) {
    console.log('error in add paypal agreement');
    throw normalizeError(err);
  }
};

export const disablePayPalAgreement = async (
  language: string
): Promise<boolean> => {
  try {
    const { data }: { data: boolean } = await http.get(
      '/autoRenew/disablePayPalAgreement',
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    return data;
  } catch (err) {
    console.log('error in disable paypal agreement');
    throw normalizeError(err);
  }
};

// USER ACCOUNT INFO
export const getUserAccountData = async (
  language: string
): Promise<UserDataResponse> => {
  try {
    const { data }: { data: any } = await http.get('/userEdit', {
      headers: {
        'Accept-Language': language,
      },
    });

    return normalizeUserAccountData(data);
  } catch (err) {
    throw normalizeError(err);
  }
};

export const onUpdateUserAccountData = async (
  language: string,
  data: any
): Promise<boolean> => {
  if (data.password && data.password === '') {
    delete data.password;
  }
  const normalizedData: UserDataRequest = normalizeUpdateUserData(data);

  try {
    const { data }: { data: boolean } = await http.post(
      '/userEdit',
      {
        ...normalizedData,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err, 'error in update account');
    throw normalizeFormSignupError(err);
  }
};

export const upgradePriceEval = async (
  language: string,
  product: ProductVersion,
  connections: number,
  teamId: number
): Promise<IUpgradeProductForEvalPriceResponse> => {
  const requestBody = normalizeProductToGetEval(product, connections, teamId);

  try {
    const { data }: { data: IUpgradeProductForEvalPriceResponse } =
      await http.post(
        `/upgradePriceEval/${product.upgradeId}`,
        {
          ...requestBody,
        },
        {
          headers: {
            'Accept-Language': language,
          },
        }
      );

    return data;
  } catch (err) {
    console.log('error in upgradePriceEval');
    throw normalizeError(err);
  }
};

export const addUpgradeToCart = async (
  language: string,
  product: ProductVersion,
  connections: number,
  teamId: number
): Promise<ProductVersion> => {
  const requestBody = normalizeProductToGetEval(product, connections, teamId);

  try {
    const { data }: { data: ProductVersion } = await http.post(
      `/addUpgradeToCart/${product.upgradeId}`,
      {
        ...requestBody,
      },
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );

    const localId = product?.localId ?? '';

    const formatted = normalizeUpgradedProductForCart(
      data,
      product?.profiles,
      localId
    );

    return formatted;
  } catch (err) {
    console.log('error in add upgrade to cart');
    throw normalizeError(err);
  }
};

export async function getDashboardDiscount(
  language: string
): Promise<UserDashboardDiscount> {
  try {
    const { data }: { data: any } = await http.get('/dashboard/discount', {
      headers: {
        'Accept-Language': language,
      },
    });
    return data;
  } catch (err) {
    console.log('error in getDashboardDiscount');
    throw normalizeError(err);
  }
}

// ALERTS API

export const getAlerts = async (
  language: string
): Promise<any> => {
  try {
    const { data }: { data: any } = await http.get(
      '/alerts',
      {
        headers: {
          'Accept-Language': language,
        },
      }
    );
    return data;
  } catch (err) {
    console.log('error in get Alerts');
    throw normalizeError(err);
  }
};

// TRIALS API

export async function loadInvite(
  token: string,
): Promise<UserInvite> {
  try {
    const { data }: { data: UserInvite } = await http.get('/invite/' + token);
    return data;
  } catch (err) {
    console.log('error loading invite');
    throw normalizeError(err);
  }
}

export async function acceptInvite(
  token: string,
): Promise<void> {
  try {
    await http.post('/invite/' + token);
  } catch (err) {
    console.log('error using invite');
    throw normalizeError(err);
  }
}
